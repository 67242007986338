.light {
  --text-color: var(--blue);
  --bg-color: var(--beige);
}

.dark {
  --text-color: var(--white);
  --bg-color: var(--black);
}

.wrapper {
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-80);
  display: grid;
  grid-column: full-start / full-end !important;
  grid-template-columns: var(--grid-template-desktop);
  align-items: center;
  padding: 0;
  overflow: hidden;
}

.faq {
  grid-area: 1 / 2 / 1 / 7;
}

.carousel {
  grid-area: 1 / 7 / 1 / full-end;
}

.title{
  padding: var(--spacing-80) var(--spacing-80) var(--spacing-40);
}

.questions {
  padding: 0 var(--spacing-80) var(--spacing-80);
}

.questions > *{
  border-bottom: 1px solid var(--grey);
}

.questions > *:last-child{
  border: none;
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: var(--grid-template-mobile);
  }
  
  .faq {
    grid-area: 1 / full-start / 1 / full-end;
  }
  
  .carousel {
    grid-area: 2 / full-start / 2 / full-end;
  }

  .title{
    padding: var(--spacing-24) var(--spacing-24) var(--spacing-24);
  }

  .questions {
    padding: 0 var(--spacing-24) var(--spacing-24);
  }
}